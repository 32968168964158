/**
 * Company: SPHMedia
 * Description: [MySPH Login button component]
 */

import iconProfile from "@assets/header/icon-profile-new.svg";
import { MYSPH_NOT_AVAILABLE } from "@helper/getEnvVariables";
import { mySPHOpenLogin } from "@helper/mySPH";
import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

type MySphLoginButtonProps = {
  buttonStyle?: string;
  textStyle?: string;
  iconWidth?: string;
  iconHeight?: string;
  buttonText?: string;
  iconImage?: string;
  iconFont?: boolean;
  textOnly?: boolean;
};

/**
 * MySphLoginButton
 * Description: [return mysph login button]
 * @param props - The props required by the component (if any)
 * @returns The Login Button
 */
export default function MySphLoginButton({
  buttonStyle,
  textStyle,
  iconWidth,
  iconHeight,
  buttonText = "Daftar Masuk",
  iconImage = iconProfile,
  iconFont = false,
  textOnly = false,
}: MySphLoginButtonProps): ReactElement | null {
  if (MYSPH_NOT_AVAILABLE) {
    return null;
  }
  return (
    <>
      <button
        onClick={() => {
          mySPHOpenLogin();
        }}
        className={twMerge("flex gap-1 items-center min-w-max", buttonStyle)}
      >
        {!textOnly ? (
          iconFont ? (
            <i className="before:content-['\e812'] text-lg text-black-100" />
          ) : (
            <img
              src={iconImage}
              alt="profile-icon"
              width={iconWidth}
              height={iconHeight}
            />
          )
        ) : null}

        <span className={twMerge("w-full font-regular", textStyle)}>
          {buttonText}
        </span>
      </button>
    </>
  );
}
