import CASBlock from "@components/CASBlock/CASBlock";
import MySphLoginButton from "@components/HeaderNew/MySphLoginButton";
import { CAS_CDP_PAYWALL_ID } from "@helper/getEnvVariables";
import useCasScript from "@hooks/useCasScript";

import RegWallSignupHandler from "./RegwallSignupHandler";

export type ArticleBannerProps = {
  isInfiniteScrollArticle: boolean;
  pageNumber: number;
  isGenG?: boolean;
};

export default function RegistrationBanner({
  isInfiniteScrollArticle,
  pageNumber,
}: ArticleBannerProps) {
  const { isScriptReady: isCasScriptReady } = useCasScript();
  const hrStyle =
    "basis-2/12 md:basis-3/12 xl:basis-4/12 border-b-grey-200 border-b-[1px] border-t-grey-650";
  const casBlockProps = {
    key: `cas-${CAS_CDP_PAYWALL_ID}`,
    casId: CAS_CDP_PAYWALL_ID,
    ...(isInfiniteScrollArticle && { index: pageNumber }),
  };

  return (
    <div>
      <div className="relative">
        <div className="absolute bg-white-100 bg-opacity-70 w-full h-[120px] bottom-10 md:bottom-8"></div>
        <div className="flex text-xs items-center gap-1 px-xxs justify-around">
          <hr className={hrStyle} />
          <div className="md:basis-4/12 flex flex-col md:flex-row justify-center gap-1">
            <span className="text-black-100">Already a registered user?</span>
            <MySphLoginButton
              buttonText="Log in"
              textOnly={true}
              textStyle="text-blue-800"
            />
          </div>
          <hr className={hrStyle} />
        </div>
      </div>
      {isCasScriptReady && <CASBlock {...casBlockProps} />}
      <RegWallSignupHandler />
    </div>
  );
}
