import fetchBHApi from "@helper/fetchBHApi";
import useSwrApi from "@hooks/useSwrApi";
import type { BHBasePageProps } from "@pages/BHBasePage";
import BHBasePage from "@pages/BHBasePage";
import { type PodcastProcessedResponse } from "@transformer/usePodcastResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import PodcastMetaData from "./PodcastMetaData";
import PodcastNew from "./PodcastNew";

export default function PodcastPage() {
  const podcastApi = "podcasts?size=8";
  const { data } = useSwrApi<PodcastProcessedResponse[]>(podcastApi);
  const { level1: id } = useParams();
  const [podcasts, setPodcasts] = useState<PodcastProcessedResponse[]>();
  const [size, setSize] = useState(8);
  const [playing, setPlaying] = useState<
    PodcastProcessedResponse | undefined
  >();

  useEffect(() => {
    const fetchData = async () => {
      if (!data || data.length === 0) return;
      let nowPlaying = false;
      try {
        const podcast: PodcastProcessedResponse[] = id
          ? await fetchBHApi<PodcastProcessedResponse[]>( // eslint-disable-next-line prettier/prettier
              `podcasts/${id}`, // eslint-disable-next-line prettier/prettier
              "GET", // eslint-disable-next-line prettier/prettier
            )
          : data;

        setPlaying(podcast[0]);
        nowPlaying = id ? false : true;
      } catch (error) {
        nowPlaying = true;
        setPlaying(data[0]);
      }

      const updatedPodcasts = data.map((podcast, index) =>
        index === 0
          ? { ...podcast, nowPlaying: nowPlaying ? true : false }
          : podcast,
      );
      setPodcasts(updatedPodcasts);
    };

    void fetchData();
  }, [id, data]);

  const handleNowPlaying = useMemo(
    () => (id: string) => {
      setPodcasts(
        (prevValue) =>
          prevValue &&
          prevValue.map((video) => ({
            ...video,
            nowPlaying: video.id === id,
          })),
      );
    },
    [setPodcasts],
  );

  const handleOnClickThumbnail = useCallback(
    (props: PodcastProcessedResponse) => {
      // eslint-disable-next-line react/prop-types
      const { id } = props;
      handleNowPlaying(id);
      setPlaying(props);
    },
    [handleNowPlaying, setPlaying],
  );

  const handleLoadMorePodcasts = async () => {
    const morePodcasts = await fetchBHApi<PodcastProcessedResponse[]>(
      `podcasts?size=${size}`,
      "GET",
    );
    setPodcasts((prevPodcast) => {
      const combinedPodcasts = [...(prevPodcast || []), ...morePodcasts];

      const uniquePodcastsMap = new Map();
      combinedPodcasts.forEach((podcast) =>
        uniquePodcastsMap.set(podcast.id, podcast),
      );
      const uniquePodcasts = Array.from(uniquePodcastsMap.values());
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return uniquePodcasts;
    });
    setSize((prevValue) => prevValue + 8);

    // This avoids GTM push.
    return 0;
  };

  const sectionName = "podcasts";
  const { gaData, metaTags } = PodcastMetaData(sectionName);
  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const basePageProps: BHBasePageProps = {
    uniqueSectionName: sectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
    hideScrollableSectionMenu: true,
  };

  const renderPodcastPage = () => {
    return (
      <PodcastNew
        playing={playing}
        podcasts={podcasts}
        sectionName={sectionName}
        pageAdTargetValue={pageAdTargetValue}
        handleLoadMorePodcasts={handleLoadMorePodcasts}
        handleOnClickThumbnail={handleOnClickThumbnail}
      />
    );
  };

  return <BHBasePage {...basePageProps}>{renderPodcastPage()}</BHBasePage>;
}
