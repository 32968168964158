import LockIcon from "@assets/webinar/Icon-premium.svg";
import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import MySphLoginButton from "@components/HeaderNew/MySphLoginButton";
import { Image } from "@elements/ResponsiveImage/Image";
import { BH_BASE_URL, WEBINAR_ESHOP_LINK } from "@helper/getEnvVariables";
import { getUserType } from "@helper/mySPH";
import { OKTAUserTypeEnum } from "@src/app/store/useOKTAUserStore";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { RefObject, useEffect, useState } from "react";

type Props = {
  playing: LatestVideoResponse;
  forwadedRef: RefObject<HTMLDivElement>;
  onEnded?: () => void;
  nextPlaylist?: () => void;
};

export default function PremiumVideoPlayer({
  playing,
  forwadedRef,
  onEnded,
  nextPlaylist,
}: Props) {
  const { id, thumbnail } = playing;
  const eshopLink = `${WEBINAR_ESHOP_LINK}${id}&go_back_url=${encodeURIComponent(BH_BASE_URL)}webinar`;
  const [isRegUser, setIsRegUser] = useState<boolean>(false);
  const [isSubUser, setIsSubUser] = useState<boolean>(false);

  useEffect(() => {
    // Get the user type (e.g., y-sub, y-anoy, etc.)
    const userType = getUserType();
    switch (userType) {
      case OKTAUserTypeEnum.SUBSCRIBER:
        setIsSubUser(true);
        break;
      case OKTAUserTypeEnum.REGISTERED:
        setIsRegUser(true);
        break;
      default:
        setIsSubUser(false);
        setIsRegUser(false);
        break;
    }
  }, []);

  return (
    <>
      {isSubUser ? (
        <BrightcoveVideo
          videoId={id}
          forwardedRef={forwadedRef}
          onEnded={onEnded}
          nextPlaylist={nextPlaylist}
        />
      ) : (
        <div className="relative">
          <Image
            objectFit="contain"
            src={thumbnail}
            srcWidth={867}
            srcHeight={488}
            alt="brightcove video"
            dataTestId="image"
          />
          <div className="absolute top-0 w-full h-full flex flex-col items-center justify-center">
            <div className="bg-black-100 opacity-80 w-full h-full"></div>
            <div className="absolute flex flex-col items-center">
              <div className="flex flex-col gap-2 lg:gap-3 items-center">
                <img
                  src={LockIcon}
                  width={60}
                  height={60}
                  alt="lock-icon"
                  className="hidden lg:block"
                />
                <img
                  src={LockIcon}
                  width={36}
                  height={36}
                  alt="lock-icon"
                  className="lg:hidden"
                />
                <span className="text-xs lg:text-[20px] font-medium text-white-100">
                  Langgan untuk tonton
                </span>
                <a
                  href={eshopLink}
                  className="w-[92px] h-[26px] bg-violet-200 pt-[3px] lg:pt-0 leading-[19px] lg:leading-[25px]
                  text-xxs lg:text-sm font-semibold text-white-100 text-center rounded-xl"
                >
                  Langgan
                </a>
              </div>
              {!isRegUser && (
                <>
                  <hr className="w-[230px] h-[1px] bg-grey-400 my-3 lg:my-6" />
                  <div className="flex gap-2 items-center">
                    <span className="text-xxs lg:text-xs font-semimedium text-white-100">
                      Sudah ada langganan?
                    </span>
                    <MySphLoginButton
                      buttonText="Log masuk"
                      textOnly={true}
                      textStyle="text-white-200 text-[12px] px-3 lg:px-4 lg:py-[2px] border border-white-200 rounded-2xl"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
