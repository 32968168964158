import SubSectionNav from "@components/SubSectionNav/SubSectionNav";
import { tagsSubSectionMapping } from "@components/SubSectionNav/subSectionNavObj";
import SideBlockAds from "@container/SideBlockAds";
import { SectionTitleV2 } from "@elements/Typography/TitleV2";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import WidgetWrapper from "@pages/Home/HomePageLayout/WidgetWrapper";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";

import type { KeywordPayloadResponseType } from "./Keyword.server";
import KeywordArticlesList from "./KeywordArticleList";
import KeywordMetaData from "./KeywordMetaData";

export function KeywordPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<KeywordPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }
  /* eslint-disable @typescript-eslint/no-unnecessary-condition */
  if (dataLoaderResponse.type === ResponseType.SUCCESS) {
    const keywordArticles = dataLoaderResponse.payload.data;
    const tagAlias = dataLoaderResponse.payload.tagAlias;
    const articleCount = dataLoaderResponse.payload.totalArticlesCount;
    const sectionName = "tags";
    let tagName = "";

    for (const tag of keywordArticles[0].tags || []) {
      if (tag.alias?.includes(tagAlias)) {
        if (tag.name !== undefined) {
          tagName = tag.name;
          break;
        }
      }
    }

    const pageAdTargetValue = PageAdTargetingTypeEnum.TAG;
    const keywordSlotTarget: AdTargetingType<ArticleAdTargetKeys, string>[] = [
      { key: ArticleAdTargetKeys.TAGS, value: tagName.toLowerCase() },
    ];

    const { displaySectionName, uniqueSectionName } =
      tagsSubSectionMapping[tagName] || {};

    const { gaData, metaTags } = KeywordMetaData({ tagName, tagAlias });
    const basePageProps: BHBasePageProps = {
      uniqueSectionName: sectionName,
      gaData: gaData,
      metaTags: metaTags,
      pageAdTargetValue: pageAdTargetValue,
      ...(displaySectionName && {
        hideScrollableSectionMenu: true,
      }),
      adsSlotTarget: keywordSlotTarget,
    };

    return (
      <BHBasePage {...basePageProps}>
        {displaySectionName && (
          <WidgetWrapper>
            <SectionTitleV2 sectionName={displaySectionName} />
            <SubSectionNav
              sectionName={uniqueSectionName}
              displaySection={displaySectionName || ""}
            />
          </WidgetWrapper>
        )}
        <div className="lg:max-w-screen-lg xl:max-w-screen-cxl m-auto px-[10px] lg:px-[5px] flex flex-col lg:flex-row w-full gap-4">
          <KeywordArticlesList
            articles={keywordArticles}
            tagAlias={tagAlias}
            tagName={tagName}
            articleCount={articleCount}
            sectionName={sectionName}
            adsSlotTarget={keywordSlotTarget}
          />
          <SideBlockAds
            adsSectionName={sectionName}
            adsOnly={true}
            pageAdTargetingProps={pageAdTargetValue}
            articleSlotTarget={keywordSlotTarget}
          />
        </div>
      </BHBasePage>
    );
  }
}
