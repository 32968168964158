/**
 * Company: SPHMedia
 * Description: [GE Banner]
 */

import GEIcon from "@assets/ge2025/ge-title-icon.svg";
import Link from "@elements/Typography/Link";
import { ReactElement } from "react";

/**
 * GEBanner
 * Description: [GE Banner component]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GEBanner(): ReactElement {
  const landingPagePath = "ge2025-pilihan-raya-umum?ref=banner";

  return (
    <div className="flex lg:justify-center items-center bg-gradient-to-r from-blue-950 to-red-450 py-2">
      <div className="gap-2 items-center hidden lg:flex">
        <img src={GEIcon} alt="ge-icon" width={30} height={30} />
        <Link
          destination={landingPagePath}
          className="text-md font-medium text-white-100 font-primary underline leading-[100%] mt-1"
        >
          Dapatkan berita terkini menjelang Pilihan Raya Umum 2025 di sini
        </Link>
      </div>
      <div className="flex lg:hidden gap-2 items-center pl-3">
        <img src={GEIcon} alt="ge-icon" width={40} height={40} />
        <div className="flex flex-col gap-[2px]">
          <Link
            destination={landingPagePath}
            className="text-[12px] font-semibold text-white-100 font-primary underline leading-[100%]"
          >
            Dapatkan berita terkini menjelang Pilihan Raya Umum 2025 di sini
          </Link>
        </div>
      </div>
    </div>
  );
}
