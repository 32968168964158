import { replaceDashAndWithSpace } from "@helper/stringTransform";
import { findStyleBySectionAndSubCategory } from "@helper/utils";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import Link from "./Link";

export type SectionTitleElement = "h1" | typeof Link;
type SectionTitleV2Props = {
  sectionName?: string;
  elementLink?: string;
  className?: string;
  Element?: SectionTitleElement;
  parentSection?: string;
  iconStyle?: string;
};

export function SectionTitleV2({
  sectionName,
  elementLink,
  className,
  Element = "h1",
  parentSection,
  iconStyle = "",
}: SectionTitleV2Props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isMobWebView = searchParams.get("mobwebview");
  if (!sectionName) return <></>;
  const selectedColor = parentSection
    ? findStyleBySectionAndSubCategory(parentSection)?.selectedColor || ""
    : findStyleBySectionAndSubCategory(sectionName)?.selectedColor || "";

  const cleanedUrl = elementLink
    ? elementLink.startsWith("/")
      ? elementLink.slice(1)
      : elementLink
    : "";

  return sectionName && !isMobWebView ? (
    <Element
      destination={cleanedUrl}
      className={twMerge(
        "text-[11px] lg:text-lg text-primary font-[700] lg:font-regular capitalize mb-2",
        `text-${selectedColor}`,
        className,
      )}
    >
      <span
        className={twMerge(
          "lg:hidden text-[16px] font-regular mr-[8px]",
          iconStyle,
        )}
        style={{
          fontFamily: "Noto Sans Symbols",
        }}
      >
        {"⌂ >"}
      </span>
      {replaceDashAndWithSpace(sectionName)}
    </Element>
  ) : null;
}
